import { LoginRequest } from '../models/login.model';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(readonly payload: LoginRequest) {}
}

export class SignInWithGoogle {
  static readonly type = '[Auth] Sign In With Google';
}

export class GoogleSignInSuccess {
  static readonly type = '[Auth] Google Sign In Success';
  constructor(readonly payload: { token: string; userId: string }) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}
